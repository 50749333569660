<template>
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="col-12">
            <div class="row row-cards">
                <div class="col-sm-6 col-lg-3">
                <div class="card card-sm">
                    <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                        <span class="bg-info text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bug" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 9v-1a3 3 0 0 1 6 0v1"></path>
                                <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"></path>
                                <line x1="3" y1="13" x2="7" y2="13"></line>
                                <line x1="17" y1="13" x2="21" y2="13"></line>
                                <line x1="12" y1="20" x2="12" y2="14"></line>
                                <line x1="4" y1="19" x2="7.35" y2="17"></line>
                                <line x1="20" y1="19" x2="16.65" y2="17"></line>
                                <line x1="4" y1="7" x2="7.75" y2="9.4"></line>
                                <line x1="20" y1="7" x2="16.25" y2="9.4"></line>
                            </svg>
                        </span>
                        </div>
                        <div class="col">
                        <div class="font-weight-medium">
                            {{ $t('bugsPage.headerPage.block_1') }}
                        </div>
                        <div class="text-muted">
                            {{ BugsInfo.length }}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                <div class="card card-sm">
                    <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                        <span class="bg-primary text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                                <line x1="9" y1="12" x2="15" y2="12"></line>
                                <line x1="12" y1="9" x2="12" y2="15"></line>
                            </svg>
                        </span>
                        </div>
                        <div class="col">
                        <div class="font-weight-medium">
                            {{ $t('bugsPage.headerPage.block_2') }}
                        </div>
                        <div class="text-muted">
                            {{ getCountBugs(0).length }}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                <div class="card card-sm">
                    <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                        <span class="bg-warning text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-question" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="10" cy="10" r="7"></circle>
                                <path d="M21 21l-6 -6"></path>
                                <line x1="10" y1="13" x2="10" y2="13.01"></line>
                                <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474"></path>
                            </svg>
                        </span>
                        </div>
                        <div class="col">
                        <div class="font-weight-medium">
                            {{ $t('bugsPage.headerPage.block_3') }}
                        </div>
                        <div class="text-muted">
                            {{ getCountBugs(1).length }}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                <div class="card card-sm">
                    <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                        <span class="bg-success text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M7 12l5 5l10 -10"></path>
                                <path d="M2 12l5 5m5 -5l5 -5"></path>
                            </svg>
                        </span>
                        </div>
                        <div class="col">
                        <div class="font-weight-medium">
                            {{ $t('bugsPage.headerPage.block_4') }}
                        </div>
                        <div class="text-muted">
                            {{ getCountBugs(2).length }}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <hr>
        <a v-if="!UserInfo.id" @click="checkAuth()" href="#" class="btn btn-outline-info w-100">
            {{ $t('bugsPage.headerPage.addBugButton') }}
        </a>
        <a v-if="UserInfo.id" @click="openAddBugModal()" data-bs-toggle="modal" data-bs-target="#modal-add-bug" href="#" class="btn btn-outline-info w-100">
            {{ $t('bugsPage.headerPage.addBugButton') }}
        </a>
        <div class="container">
          <div class="row">
            <div class="col" style="margin-top: 30px;">
              <div class="mb-3">
                <select v-model="filters.status" @change="changeFilterStatus()" class="form-select" >
                  <option v-for="(st, index) in $t('bugsPage.filters.bugStatus')" :key="index" :value="index">{{st}}</option>
                </select>
              </div>
            </div>
            <div v-if="UserInfo.id" class="col-6" style="margin-top: 40px;">
              <label class="form-check form-switch">
                <input v-model="filters.myreports" @change="changeFilterStatus()" class="form-check-input" type="checkbox">
                <span class="form-check-label">{{ $t('bugsPage.filters.myReports') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="empty" v-if="currentArray.length == 0">
            <div class="empty-icon">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-report-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path>
                <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2"></path>
                <rect x="8" y="3" width="6" height="4" rx="2"></rect>
                <path d="M8 11h4"></path>
                <path d="M8 15h3"></path>
                <circle cx="16.5" cy="17.5" r="2.5"></circle>
                <path d="M18.5 19.5l2.5 2.5"></path>
              </svg>
            </div>
            <p class="empty-title">{{ $t('bugsPage.filters.notFoundResult.title') }}</p>
            <p class="empty-subtitle text-muted">
              {{ $t('bugsPage.filters.notFoundResult.text') }}
            </p>
          </div>
            <div class="container-xl">
                <div class="row row-cards">
                    <div v-for="bug in currentArray" :key="bug.id" v-show="bug.vulnerability == 0 || bug.vulnerability == 1 && UserInfo.dev == 1 || bug.vulnerability == 1 && UserInfo.id == bug.owner" class="col-md-6 col-lg-12">
                        <div class="card">
                          <div v-if="bug.status == 0" class="ribbon ribbon-bookmark bg-blue">NEW</div>
                            <div class="card-stamp">
                                <div class="card-stamp-icon text-white" v-bind:class="{ 'bg-info' : bug.status == 0, 'bg-primary' : bug.status == 1, 'bg-green' : bug.status == 2, 'bg-danger' : bug.status == 3, 'bg-warning' : bug.status == 4 }">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bug" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M9 9v-1a3 3 0 0 1 6 0v1"></path>
                                    <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"></path>
                                    <line x1="3" y1="13" x2="7" y2="13"></line>
                                    <line x1="17" y1="13" x2="21" y2="13"></line>
                                    <line x1="12" y1="20" x2="12" y2="14"></line>
                                    <line x1="4" y1="19" x2="7.35" y2="17"></line>
                                    <line x1="20" y1="19" x2="16.65" y2="17"></line>
                                    <line x1="4" y1="7" x2="7.75" y2="9.4"></line>
                                    <line x1="20" y1="7" x2="16.25" y2="9.4"></line>
                                </svg>
                                </div>
                            </div>
                            <div class="card-status-bottom" v-bind:class="{ 'bg-info' : bug.status == 0, 'bg-primary' : bug.status == 1, 'bg-green' : bug.status == 2, 'bg-danger' : bug.status == 3, 'bg-warning' : bug.status == 4 }"></div>
                                <div class="card-body">
                                    <h3 class="card-title"><span class="text-muted">({{ bug.bugID }})</span> - {{ bug.name }}</h3>
                                    <h5 class="status status-info" style="margin-bottom: 10px;" v-if="bug.status == 0">{{ $t('bugsPage.listBugs.bugStatus.0') }}</h5>
                                    <h5 class="status status-primary" style="margin-bottom: 10px;" v-if="bug.status == 1">{{ $t('bugsPage.listBugs.bugStatus.1') }}</h5>
                                    <h5 class="status status-green" style="margin-bottom: 10px;" v-if="bug.status == 2">{{ $t('bugsPage.listBugs.bugStatus.2') }}</h5>
                                    <h5 class="status status-danger" style="margin-bottom: 10px;" v-if="bug.status == 3">{{ $t('bugsPage.listBugs.bugStatus.3') }}</h5>
                                    <h5 class="status status-warning" style="margin-bottom: 10px;" v-if="bug.status == 4">{{ $t('bugsPage.listBugs.bugStatus.4') }}</h5>
                                    <blockquote class="blockquote">
                                        <p>{{ bug.description }}</p>
                                        <footer class="blockquote-footer">{{ bug.type ? $t('bugsPage.listBugs.bugTypes.1') : $t('bugsPage.listBugs.bugTypes.0') }}</footer>
                                    </blockquote>
                                </div>
                                <div class="card-footer">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <a @click="selectBug(bug.id)" data-bs-toggle="modal" data-bs-target="#modal-desc-bug" href="#" class="btn btn-ghost-info w-100">
                                              {{ $t('bugsPage.listBugs.descriptionButton') }}
                                            </a>
                                        </div>
                                        <div v-if="bug.owner == UserInfo.id || UserInfo.dev == 1" class="col-auto ms-auto">
                                            <div class="dropdown">
                                                <button class="btn dropdown-toggle align-text-top" data-bs-toggle="dropdown">
                                                    {{ $t('bugsPage.listBugs.actions.name') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                <a @click="openUpdateBugModal(bug.id)" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-update-bug">
                                                    {{ $t('bugsPage.listBugs.actions.updateBug') }}
                                                </a>
                                                <hr v-if="UserInfo.dev == 1" style="margin: 0.1rem 0;">
                                                <a v-if="UserInfo.dev == 1" @click="selectBug(bug.id)" data-bs-toggle="modal" data-bs-target="#modal-status-bug" class="dropdown-item text-warning" href="#">
                                                    {{ $t('bugsPage.listBugs.actions.setStatusBug') }}
                                                </a>
                                                <hr v-if="UserInfo.dev == 1" style="margin: 0.1rem 0;">
                                                <a class="dropdown-item text-danger" @click="selectBug(bug.id)" href="#" data-bs-toggle="modal" data-bs-target="#modal-delete-bug">
                                                    {{ $t('bugsPage.listBugs.actions.deleteBug') }}
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal add bug -->
        <div class="modal modal-blur fade" id="modal-add-bug" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('bugsPage.listBugs.modalAddBug.title') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
                <p>{{ $t('bugsPage.listBugs.modalAddBug.notice.rules') }}</p>
                <p>{{ $t('bugsPage.listBugs.modalAddBug.notice.rule_1') }}</p>
                <p>{{ $t('bugsPage.listBugs.modalAddBug.notice.rule_2') }}</p>
                <p>{{ $t('bugsPage.listBugs.modalAddBug.notice.rule_3') }}</p>
                <p class="text-danger">{{ $t('bugsPage.listBugs.modalAddBug.notice.rule_desc') }}</p>
            </div>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.name') }}</label>
              <input v-model="modalAddBug.name" type="text" class="form-control" name="example-text-input">
            </div>
            <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.name') }}</label>
            <div class="form-selectgroup-boxes row mb-3">
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input id="radio_1" type="radio" v-bind:value="0" name="report-type" v-model="modalAddBug.type" class="form-selectgroup-input" :checked="modalAddBug.type == 0">
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_1') }}</span>
                      <span class="d-block text-muted">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_1_desc') }}</span>
                    </span>
                  </span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input id="radio_1" type="radio" v-bind:value="1" name="report-type" v-model="modalAddBug.type" class="form-selectgroup-input" :checked="modalAddBug.type == 1">
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_2') }}</span>
                      <span class="d-block text-muted">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_2_desc') }}</span>
                    </span>
                  </span>
                </label>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.description') }}</label>
                  <textarea v-model="modalAddBug.description" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.steps') }}</label>
                  <textarea v-model="modalAddBug.steps" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.whowork') }}</label>
                  <textarea v-model="modalAddBug.whowork" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.factresult') }}</label>
                  <textarea v-model="modalAddBug.factresult" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.note') }}</label>
                  <textarea v-model="modalAddBug.note" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.edi') }}</label>
                  <textarea v-model="modalAddBug.edivence" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="mb-3" style="margin-top: 20px;">
                            <label class="form-label text-danger">{{ $t('bugsPage.listBugs.modalAddBug.vulnerability.name') }}</label>
                            <div class="form-selectgroup">
                              <label class="form-selectgroup-item">
                                <input @click="modalAddBug.vulnerability = 1" type="radio" name="icons" value="home" class="form-selectgroup-input">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l5 5l10 -10"></path>
                                  </svg>
                                  {{ $t('bugsPage.listBugs.modalAddBug.vulnerability.yesButton') }}</span>
                              </label>
                              <label class="form-selectgroup-item">
                                <input @click="modalAddBug.vulnerability = 0" type="radio" name="icons" value="user" class="form-selectgroup-input" checked="">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/user -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                  {{ $t('bugsPage.listBugs.modalAddBug.vulnerability.noButton') }}</span>
                              </label>
                            </div>
                          </div>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="btn btn-ghost-primary" data-bs-dismiss="modal">
              {{ $t('bugsPage.listBugs.modalAddBug.cancelButton') }}
            </a>
            <a href="#" @click="addBug()" data-bs-dismiss="modal" class="btn btn-primary">
              <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              {{ $t('bugsPage.listBugs.modalAddBug.createBugReport') }}
            </a>
          </div>
        </div>
      </div>
    </div>
        <!-- -->

        <!-- Modal update bug -->
        <div class="modal modal-blur fade" id="modal-update-bug" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('bugsPage.listBugs.modalUpdateBug.title') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.name') }}</label>
              <input v-model="modalUpdateBug.name" type="text" class="form-control" name="example-text-input">
            </div>
            <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.name') }}</label>
            <div class="form-selectgroup-boxes row mb-3">
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input id="radio_2" type="radio" v-bind:value="0" name="report-type" v-model="modalUpdateBug.type" class="form-selectgroup-input" :checked="modalUpdateBug.type == 0">
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_1') }}</span>
                      <span class="d-block text-muted">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_1_desc') }}</span>
                    </span>
                  </span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input id="radio_2" type="radio" v-bind:value="1" name="report-type" v-model="modalUpdateBug.type" class="form-selectgroup-input" :checked="modalUpdateBug.type == 1">
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_2') }}</span>
                      <span class="d-block text-muted">{{ $t('bugsPage.listBugs.modalAddBug.reportTypes.type_2_desc') }}</span>
                    </span>
                  </span>
                </label>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.description') }}</label>
                  <textarea v-model="modalUpdateBug.description" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.steps') }}</label>
                  <textarea v-model="modalUpdateBug.steps" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.whowork') }}</label>
                  <textarea v-model="modalUpdateBug.whowork" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.factresult') }}</label>
                  <textarea v-model="modalUpdateBug.factresult" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.note') }}</label>
                  <textarea v-model="modalUpdateBug.note" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="col-lg-12" style="margin-top: 10px;">
                <div>
                  <label class="form-label">{{ $t('bugsPage.listBugs.modalAddBug.edi') }}</label>
                  <textarea v-model="modalUpdateBug.edivence" class="form-control" rows="3"></textarea>
                </div>
              </div>
              <div class="mb-3" style="margin-top: 20px;">
                            <label class="form-label text-danger">{{ $t('bugsPage.listBugs.modalAddBug.vulnerability.name') }}</label>
                            <div class="form-selectgroup">
                              <label class="form-selectgroup-item">
                                <input v-model="modalUpdateBug.vulnerability" v-bind:value="1" type="radio" name="icons" class="form-selectgroup-input" v-bind:checked="modalUpdateBug.vulnerability == 1">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l5 5l10 -10"></path>
                                  </svg>
                                  {{ $t('bugsPage.listBugs.modalAddBug.vulnerability.yesButton') }}</span>
                              </label>
                              <label class="form-selectgroup-item">
                                <input v-model="modalUpdateBug.vulnerability" v-bind:value="0" type="radio" name="icons" class="form-selectgroup-input" v-bind:checked="modalUpdateBug.vulnerability == 0">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/user -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                  {{ $t('bugsPage.listBugs.modalAddBug.vulnerability.noButton') }}</span>
                              </label>
                            </div>
                          </div>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="btn btn-ghost-primary" data-bs-dismiss="modal">
              {{ $t('bugsPage.listBugs.modalAddBug.cancelButton') }}
            </a>
            <a href="#" @click="updateBug()" data-bs-dismiss="modal" class="btn btn-primary">
              <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              {{ $t('bugsPage.listBugs.modalUpdateBug.updateButton') }}
            </a>
          </div>
        </div>
      </div>
    </div>
        <!-- -->

        <!-- Modal desc bug -->
        <div class="modal modal-blur fade" id="modal-desc-bug" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('bugsPage.listBugs.modalDescription.title') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">


            <div class="col-md-6 col-lg-12">
                <div class="card">
                  <div class="card-stamp">
                    <div class="card-stamp-icon bg-blue">
                      <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M13 20v-16l-5 5"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.block_1') }}</h3>
                    <p v-if="selectedBug" class="text-muted">{{ getBugInfo('description') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-12">
                <div class="card">
                  <div class="card-stamp">
                    <div class="card-stamp-icon bg-blue">
                      <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 8a4 4 0 1 1 8 0c0 1.098 -.564 2.025 -1.159 2.815l-6.841 9.185h8"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.block_2') }}</h3>
                    <p v-if="selectedBug" class="text-muted">{{ getBugInfo('steps') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-12">
                <div class="card">
                  <div class="card-stamp">
                    <div class="card-stamp-icon bg-blue">
                      <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-3" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 12a4 4 0 1 0 -4 -4"></path>
                        <path d="M8 16a4 4 0 1 0 4 -4"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.block_3') }}</h3>
                    <p v-if="selectedBug" class="text-muted">{{ getBugInfo('whowork') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-12">
                <div class="card">
                  <div class="card-stamp">
                    <div class="card-stamp-icon bg-blue">
                      <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 20v-15l-8 11h10"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.block_4') }}</h3>
                    <p v-if="selectedBug" class="text-muted">{{ getBugInfo('factresult') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-12">
                <div class="card">
                  <div class="card-stamp">
                    <div class="card-stamp-icon bg-blue">
                      <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-number-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 20h4a4 4 0 1 0 0 -8h-4v-8h8"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.block_5') }}</h3>
                    <p v-if="selectedBug" class="text-muted">{{ getBugInfo('note') }}</p>
                  </div>
                </div>
              </div>
            <div v-if="selectedBug">
                <h3 class="card-title">{{ $t('bugsPage.listBugs.modalDescription.edi') }}</h3>
              <div v-for="(edi, index) in getBugInfo('edivence')" :key="index">
                  <center style="margin-top: 20px;" v-if="edi.indexOf('.png') != -1 || edi.indexOf('.jpg') != -1 || edi.indexOf('.jpeg') != -1"><a :href="edi" target="_blank"><img width="200px" height="150px" :src="edi" /></a></center>
                  <a :href="edi" v-else-if="edi.indexOf('http') != -1" target="_blank" class="btn btn-outline-info w-100">
                        {{ $t('bugsPage.listBugs.modalDescription.link') }} {{index + 1}}
                    </a>
                    <p v-else>{{edi}}</p>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-bs-dismiss="modal">{{ $t('bugsPage.listBugs.modalDescription.hideButton') }}</button>
          </div>
        </div>
      </div>
    </div>
        <!-- -->

        <!-- Modal status bug -->
        <div class="modal modal-blur fade" id="modal-status-bug" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('bugsPage.listBugs.modalSetStatus.title') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">


            <div class="mb-3">
                              <div class="form-label">{{ $t('bugsPage.listBugs.modalSetStatus.label') }}</div>
                              <select v-model="selectChangeStatus" class="form-select">
                                <option v-for="(status, index) in $t('bugsPage.listBugs.bugStatus')" :key="index" v-bind:value="index">{{status}}</option>
                              </select>
                            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-bs-dismiss="modal">{{ $t('bugsPage.listBugs.modalSetStatus.hideButton') }}</button>
            <button type="button" @click="changeStatusBug()" class="btn bg-primary" data-bs-dismiss="modal">{{ $t('bugsPage.listBugs.modalSetStatus.updateButton') }}</button>
          </div>
        </div>
      </div>
    </div>
        <!-- -->


        <!-- delete bug -->
        <div class="modal modal-blur fade" id="modal-delete-bug" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-status bg-danger"></div>
          <div class="modal-body text-center py-4">
            <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
            <h3>{{ $t('bugsPage.listBugs.modalDeleteBug.header') }}</h3>
            <div class="text-muted">{{ $t('bugsPage.listBugs.modalDeleteBug.text') }}</div>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <div class="row">
                <div class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">
                    {{ $t('bugsPage.listBugs.modalDeleteBug.cancelButton') }}
                  </a></div>
                <div @click="deleteBug()" class="col"><a href="#" class="btn btn-danger w-100" data-bs-dismiss="modal">
                    {{ $t('bugsPage.listBugs.modalDeleteBug.acceptDeleteButton') }}
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!-- -->


    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "BugsPage",
    data: function () {
      return {
        selectedBug: null,
        selectChangeStatus: 0,
        currentArray: [],
        filters: {
          status: 0,
          myreports: 0
        },
        bugStatus: {
          0: "Все",
          1: "Новый",
          2: "На рассмотрении",
          3: "Сообщено",
          4: "Не воспроизвелось",
          5: "Не достаточно данных"
        },
        modalAddBug: {
            name: null,
            type: null,
            description: null,
            steps: null,
            whowork: null,
            factresult: null,
            note: null,
            vulnerability: 0,
            edivence: null
        },
        modalUpdateBug: {
            id: null,
            name: null,
            type: null,
            description: null,
            steps: null,
            whowork: null,
            factresult: null,
            note: null,
            vulnerability: 0,
            edivence: null
        }
      }
    },
    methods: {
      ...mapActions("bugsInfo", ["LoadBugsData"]),
        addBug() {
            this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "add_bug", bugInfo: this.modalAddBug}}).then((response) => {
              if (response.data.success) {
                this.loadBugsData()
              }
            })
        },
        checkAuth() {
          this.$dtoast.pop({
            preset: 'error',
            heading: this.$t('headerPanel.linkServer.notice.getAuth.header'),
            content:  this.$t('headerPanel.linkServer.notice.getAuth.text'),
          })
        },
        changeStatusBug() {
          console.log(this.selectedBug)
          console.log(parseInt(this.selectChangeStatus))
          this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "change_bug_status", bugID: this.selectedBug, status: parseInt(this.selectChangeStatus)}}).then((response) => {
            if (response.data.success) {
              this.loadBugsData()
            }
          })
        },
        updateBug() {
            console.log(this.modalUpdateBug.vulnerability)
            this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "update_bug", bugInfo: this.modalUpdateBug}}).then((response) => {
              if (response.data.success) {
                this.loadBugsData()
              }
            })
        },
        deleteBug() {
            this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "delete_bug", bugID: this.selectedBug}}).then((response) => {
              if (response.data.success) {
                this.loadBugsData()
              }
            })
        },
        openAddBugModal() {
            this.modalAddBug.type = 0
        },
        openUpdateBugModal(id) {
            let bugInfo = this.BugsInfo.find(bug => bug.id == id)
            Object.assign(this.modalUpdateBug, bugInfo)
            this.modalUpdateBug.edivence = this.replaceAll(bugInfo.edivence.toString(), ',', '\n')
        },
        replaceAll(string, search, replace) {
          return string.split(search).join(replace);
        },
        selectBug(id) {
            this.selectedBug = id
        },
        getBugInfo(type) {
            let currentBug = this.BugsInfo.find(bug => bug.id == this.selectedBug)
            if (currentBug) return currentBug[type]
        },
        getCountBugs(type) {
            return this.BugsInfo.filter(bug => bug.status == type)
        },
        loadDefaultModalInfo() {
            this.modalAddBug = {
                name: null,
                type: null,
                description: null,
                steps: null,
                whowork: null,
                factresult: null,
                note: null,
                vulnerability: 0,
                edivence: null
            }
        },
        changeFilterStatus() {
          if (this.filters.status == 0) {
            if (this.filters.myreports) {
              this.currentArray = this.BugsInfo.filter(bug => bug.owner == this.UserInfo.id)
            } else {
              this.currentArray = this.BugsInfo
            }
          } else {
            if (this.filters.myreports) {
              this.currentArray = this.BugsInfo.filter(bug => bug.status == this.filters.status - 1 && bug.owner == this.UserInfo.id )
            } else {
              this.currentArray = this.BugsInfo.filter(bug => bug.status == this.filters.status - 1 )
            }
          }
        },
        loadBugsData() {
          this.LoadBugsData().then(response => {
              console.log(response)
              this.currentArray = this.BugsInfo
          }, error => {
              console.error(error)
          })
        }
    },
    computed: {
        ...mapState("bugsInfo", ["BugsInfo"]),
        ...mapState("userInfo", ["UserInfo"]),
    },
    created() {
        this.loadDefaultModalInfo()
        this.loadBugsData()
    }
}
</script>